import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form, { Field, FormFooter, FormHeader, FormSection } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import Button from "@atlaskit/button/standard-button";
import ButtonGroup from "@atlaskit/button/button-group";
import { LoadingButton } from "@atlaskit/button";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ResetPassword() {
  const [fields, setFields] = useState({
    email: ""
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handleChange = (fieldName, value) => {
    setFields({ ...fields, [fieldName]: value });
  };

  const handleSubmit = async (e) => {
    try {
        await sendPasswordResetEmail(auth, fields.email);
        window.alert("Email sent to " + fields.email + ".");
    } catch (err) {
        window.alert("There is no account linked to this email. Please try again.");
    }
  };

  return (
    <div>
      <h1>Reset Password</h1>
      <Form onSubmit={handleSubmit}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <FormSection>
            <Field name="email" label="Email" isRequired>
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    value={fields.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    required
                  />
                )}
            </Field>
            </FormSection>
            {error ? <p style={{ color: "red" }}>Error: {error}</p> : null}
            <FormFooter>
              <ButtonGroup>
                <Button appearance="subtle" onClick={() => navigate("/")}>
                  Cancel
                </Button>
                <LoadingButton type="submit" appearance="primary" isLoading={submitting}>
                  Send email
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
}
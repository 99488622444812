// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import {Dashboard} from "../components/pages/Dashboard";
// import React from "react";
// export default function AuthorizedRoutes() {
//     return (<Router>
//       <Routes>
//         <Route path="/" element={<Dashboard></Dashboard>}/>
//         <Route path="*" element={<main>
//               <p>Not found.</p>
//             </main>}/>
//       </Routes>
//     </Router>);
// }
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../components/pages/Dashboard";

export default function AuthorizedRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route
          path="*"
          element={
            <main>
              <p>Not found.</p>
            </main>
          }
        />
      </Routes>
    </Router>
  );
}
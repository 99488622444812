import React, { useState } from "react";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/standard-button";
import LoadingButton from "@atlaskit/button/loading-button";
import Select from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import EditSymbol from "@atlaskit/icon/glyph/edit";
import ArrowLeftSymbol from "@atlaskit/icon/glyph/arrow-left";
import ArrowRightSymbol from "@atlaskit/icon/glyph/arrow-right";
import AddSymbol from "@atlaskit/icon/glyph/add";
import Form, { Field, FormFooter, FormSection } from "@atlaskit/form";
import axios from "axios";
import { getAuth } from "firebase/auth";
import firebaseService from "../../services/firebase";

const EditProfileForm = ({ userData, page, setPage }) => {
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState(""); // State for displaying the message
  const [formData, setFormData] = useState(userData);
  const initialFormData = { ...userData };

  const resetForm = () => {
    setFormData(initialFormData);
    setDisabled(true);
  };

  // Use userData to populate form fields
  const { userName, displayName, dob, gender, email, phoneNum } = userData;

  return (
    <div style={{
        display: "flex",
        width: "312px",
        maxWidth: "100%",
        margin: "0 auto",
        flexDirection: "column",
    }}>
      <Form onSubmit={async (data) => {
        console.log("form data", data);
        try {
          const token = await firebaseService.auth.currentUser.getIdToken();
          // console.log(token);
          const user = getAuth().currentUser; // Use getAuth() to get Firebase auth object
          // if (user !== null) return
          const body = {
            displayName: data.displayName,
            gender: data.gender,
            dob: data.dob,
            phoneNum: data.phoneNum,
          }
    
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
          const req = await axios.put("http://localhost:5000/api/user", body, config);
          const successMessage = "Changes have been made!";
          setMessage(successMessage);

          return navigate("/", {
            replace: true,
            state: {
              message,
            },
          });
        } catch (err) {
          const errMessage = err.response.data.error;
          return setError(errMessage);
        }
        // return
        // return new Promise((resolve, reject) => {
        //   // Simulate an error for demonstration purposes
        //   if (data.userName === "error") {
        //     reject({ userName: "IN_USE" });
        //   } else {
        //     setTimeout(() => {
        //       resolve();
        //     }, 2000);
        //   }
        // });
      }}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <FormSection>
              <Field aria-required={true} name="userName" isDisabled={true} label="User Name" isRequired defaultValue={userName}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>
              <Field aria-required={true} name="displayName" isDisabled={disabled} label="Display Name" isRequired defaultValue={displayName}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>
              <Field aria-required={true} name="dob" label="Date of Birth" isDisabled={disabled} isRequired defaultValue={dob}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>
              <Field name="gender" label="Gender" isDisabled={disabled} id="owner" defaultValue={gender}>
                {({ fieldProps }) => (
                  <Select
                    isSearchable={false}
                    inputId={fieldProps.id}
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                      { label: "Other", value: "other" },
                    ]}
                    {...fieldProps}
                  />
                )}
              </Field>
              <Field aria-required={true} name="email" label="Email" isDisabled={true} isRequired defaultValue={email}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>
              <Field aria-required={true} name="phoneNum" label="Phone Number" isDisabled={disabled} isRequired defaultValue={phoneNum}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>
            </FormSection>

            <FormFooter>
              <ButtonGroup>
                <Button onClick={() => setDisabled(!disabled)} iconAfter={<EditSymbol label="" size="medium"/>} appearance="primary"> Edit </Button>
                <Button onClick={resetForm} iconAfter={<ArrowLeftSymbol label="" size="medium"/>} appearance="primary"> Cancel </Button>
                <LoadingButton type="submit" iconAfter={<ArrowRightSymbol label="" size="medium"/>} appearance="primary"> Save </LoadingButton>
              </ButtonGroup>
            </FormFooter>

            {message && (
              <div className="message" style={{ fontFamily: 'Arial', fontSize: '16px', color: 'green', textAlign: 'center', marginTop: '20px' }}>
                {message}
              </div>
            )}

          </form>
        )}
      </Form>
    </div>
  );
};

export default EditProfileForm;

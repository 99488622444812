import React, { useState, useEffect } from "react";
import "./App.css";
import UnauthorizedRoutes from "./routes/UnauthorizedRoutes";
import AuthorizedRoutes from "./routes/AuthorizedRoutes";
import store from "./stores/store";
import firebaseService from "./services/firebase";
import { sendEmailVerification } from "firebase/auth";

function App() {
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);

  const authStateListener = () => {
    firebaseService.auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setLoading(false);
        store.getActions().setUnauthorized();

      } else {
        setLoading(false);

        // temporary email verification
        if (!user.emailVerified) {
          window.alert("Click 'OK' to send a verification link to your email. Please follow the link to complete the verification and refresh the login page afterwards.");
          sendEmailVerification(user);
        } else {
          store.getActions().setAuthorized();
        }
      }
      setSignedIn(store.getState().authorized);
    });
  };

  useEffect(() => {
    authStateListener();

    const handleTabClose = () => {
    //  event.preventDefault();
      firebaseService.auth.signOut();
    //  return (event.returnValue = 'exit');
    }
    
    
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    }
  }, []);

  return (
    <div className="App" style={{ padding: 16 }}>
      {loading ? (
        <p>Loading...</p>
      ) : signedIn ? ( 
        <AuthorizedRoutes />
      ) : (
        <UnauthorizedRoutes />
      )}
    </div>
  );
}

export default App;

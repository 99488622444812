import React, { Fragment } from "react";
import ButtonGroup from "@atlaskit/button/button-group";
import LoadingButton from "@atlaskit/button/loading-button";
import TextField from "@atlaskit/textfield";
import Form, { ErrorMessage, Field, FormFooter, FormHeader, FormSection, RequiredAsterisk, } from "@atlaskit/form";
import axios from "axios";
import { TrelloLogo } from "@atlaskit/logo";
async function login(signIn, data) {
    console.log(data);
    const response = await axios
        .post("/auth", data)
        .then((response) => {
        console.log(response.data);
        if (response.data["Authorized"] == "True")
            signIn(true);
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
export const TrelloLogInForm = ({ signIn, setMember }) => (<div style={{
        display: "flex",
        maxWidth: "100%",
        margin: "0 auto",
        flexDirection: "column",
    }}>
    <Form onSubmit={(data) => {
        login(signIn, data);
    }}>
      {({ formProps, submitting }) => (<form {...formProps}>
          <FormHeader>
            <TrelloLogo appearance="brand"/>
            <p aria-hidden="true">
              Required fields are marked with an asterisk <RequiredAsterisk />
            </p>
          </FormHeader>
          <FormSection>
            <Field aria-required={true} name="username" label="Username" isRequired defaultValue="username">
              {({ fieldProps, error }) => (<Fragment>
                  <TextField autoComplete="off" {...fieldProps}/>
                  {error && (<ErrorMessage>Could not login, try again.</ErrorMessage>)}
                </Fragment>)}
            </Field>
            <Field aria-required={true} name="password" label="Password" defaultValue="" isRequired validate={(value) => value && value.length < 8 ? "TOO_SHORT" : undefined}>
              {({ fieldProps, error, valid, meta }) => {
            return (<Fragment>
                    <TextField type="password" {...fieldProps}/>
                  </Fragment>);
        }}
            </Field>
          </FormSection>

          <FormFooter>
            <ButtonGroup>
              {/* <Button appearance="subtle-link">Cancel</Button> */}
              <LoadingButton type="submit" appearance="primary" isLoading={submitting}>
                Log In
              </LoadingButton>
            </ButtonGroup>
          </FormFooter>
        </form>)}
    </Form>
  </div>);

import React, { useState, Fragment } from "react";
import axios from "axios";
import Button from "@atlaskit/button/standard-button";
import ButtonGroup from "@atlaskit/button/button-group";
import LoadingButton from "@atlaskit/button/loading-button";
import TextField from "@atlaskit/textfield";
import Form, { ErrorMessage, Field, FormFooter, FormHeader, FormSection, HelperMessage, RequiredAsterisk, ValidMessage, } from "@atlaskit/form";
import { useLocation, useNavigate } from "react-router-dom";
import firebaseService from "../../services/firebase"; // Include your Firebase service
import store from "../../stores/store"; // Include your Easy Peasy store
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link } from 'react-router-dom';

export default function SignInPage() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (name, value) => {
    setFields({ ...fields, [name]: value });
  };

  const handleFirebaseSignIn = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        firebaseService.auth,
        fields.email,
        fields.password
      );
      if (user) {
        if (store.getState().authorized) {
          console.log("Authorized");
        } else {
          console.log("Unauthorized");
        }

        // allow login after verification
        if (user.emailVerified) {
          navigate("/");
          store.getActions().setAuthorized();
        }
        
        console.log("Firebase Sign-In Called");
        if (store.getState().authorized) {
          console.log("Authorized");
        } else {
          console.log("Unauthorized");
        }
      }
    } catch (err) {
      console.log(err);
      setError("Invalid email address or password.");
    }
  };

  return (
    <main>
      {location.state && location.state.message ? (
        <p style={{ color: "green" }}>{location.state.message}</p>
      ) : null}
      <Form onSubmit={handleFirebaseSignIn}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <FormHeader title="Log in">
              <p aria-hidden="true">
                Required fields are marked with an asterisk <RequiredAsterisk />
              </p>
            </FormHeader>
            <FormSection>
              <Field
                aria-required={true}
                name="email"
                label="Email"
                isRequired
                defaultValue="email"
              >
                {({ fieldProps, error }) => (
                  <Fragment>
                    <TextField
                      autoComplete="off"
                      {...fieldProps}
                      value={fields.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                    {/* {!error && (
                      <HelperMessage>
                        You can use letters, numbers, and periods.
                      </HelperMessage>
                    )} */}
                    {error && (
                      <ErrorMessage>
                        This email is already in use, try another one.
                      </ErrorMessage>
                    )}
                  </Fragment>
                )}
              </Field>
              <Field
                aria-required={true}
                name="password"
                label="Password"
                defaultValue=""
                isRequired
              >
                {({ fieldProps, error, valid, meta }) => (
                  <Fragment>
                    <TextField
                      type="password"
                      {...fieldProps}
                      value={fields.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                    />
                    {/* {error && !valid && (
                      <HelperMessage>
                        Use 8 or more characters with a mix of letters, numbers,
                        and symbols.
                      </HelperMessage>
                    )}
                    {error && (
                      <ErrorMessage>
                        Password needs to be more than 8 characters.
                      </ErrorMessage>
                    )}
                    {valid && meta.dirty ? (
                      <ValidMessage>Awesome password!</ValidMessage>
                    ) : null} */}
                  </Fragment>
                )}
              </Field>
              <Link to="/signup">
              <Button appearance="subtle">
                Not a member yet?
              </Button>
              </Link>
              <Link to="/passwordreset">
              <Button appearance="subtle">
                Forgot Password?
              </Button>
              </Link>
            </FormSection>
            <ButtonGroup>
              <LoadingButton
                type="submit"
                appearance="primary"
                isLoading={submitting}
              >
                Log In
              </LoadingButton>
            </ButtonGroup>
          </form>
        )}
      </Form>
      {error ? <p style={{ color: "red" }}>Error: {error}</p> : null}
    </main>
  );
}

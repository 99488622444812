import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Form, { Field, FormFooter, FormHeader, FormSection } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import Button from "@atlaskit/button/standard-button";
import ButtonGroup from "@atlaskit/button/button-group";
import Select from "@atlaskit/select";
import { LoadingButton } from "@atlaskit/button";

export default function SignUpPage() {
  const [fields, setFields] = useState({
    userName: "",
    displayName: "",
    email: "",
    gender: "",
    dob: "",
    phoneNum: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (fieldName, value) => {
    setFields({ ...fields, [fieldName]: value });
  };

  const isPasswordStrongEnough = (password) => {
    // For example, require at least 8 characters, including numbers and special characters
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted!"); 
    if (fields.password.length < 8) {
      return setError("Password must be at least 8 characters long.");
    }
    if (!isPasswordStrongEnough(fields.password)) {
      return setError("Password should include letters, numbers, and special characters.");
    }
    if (fields.confirmPassword !== fields.password) {
      return setError("Password and confirm password must match.");
    }

    try {
      const req = await axios.post("http://localhost:5000/api/user", {
        userName: fields.userName,
        displayName: fields.displayName,
        email: fields.email,
        gender: fields.gender,
        dob: fields.dob,
        phoneNum: fields.phoneNum,
        password: fields.password
      });
      const message = req.data.success;
      return navigate("/", {
        replace: true,
        state: {
          message,
        },
      });
    } catch (err) {
      const errMessage = err.response.data.error;
      return setError(errMessage);
    }
  };

  return (
    <div>
      <h1>Sign Up</h1>
      <Form onSubmit={handleSubmit}>
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <FormSection>
            <Field name="userName" label="User Name (Unique account identifier)" isRequired>
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    value={fields.userName}
                    onChange={(e) => handleChange("userName", e.target.value)}
                    required
                  />
                )}
              </Field>
            <Field name="displayName" label="Display Name (Visible to other users)" isRequired>
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    value={fields.displayName}
                    onChange={(e) => handleChange("displayName", e.target.value)}
                    required
                  />
                )}
              </Field>
              <Field name="email" label="Email Address" isRequired>
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    value={fields.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    required
                  />
                )}
              </Field>
              <Field name="gender" label="Gender">
                {({ fieldProps }) => (
                  <Select
                    {...fieldProps}
                    value={fields.gender}
                    onChange={(value) => handleChange("gender", value)}
                    options={[
                      { label: "Select Gender", value: "" },
                      { label: "Male", value: "Male" },
                      { label: "Female", value: "Female" },
                      { label: "Other", value: "Other" },
                    ]}
                    isRequired
                  />
                )}
              </Field>
              <Field name="dob" label="Date of Birth (Optional)">
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    value={fields.dob}
                    onChange={(e) => handleChange("dob", e.target.value)}
                  />
                )}
              </Field>
              <Field name="phoneNum" label="Phone Number (Optional)">
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    value={fields.phoneNum}
                    onChange={(e) => handleChange("phoneNum", e.target.value)}
                  />
                )}
              </Field>
              <Field name="password" label="Password" isRequired>
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    type="password"
                    value={fields.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    required
                  />
                )}
              </Field>
              <Field name="confirmPassword" label="Confirm Password" isRequired>
                {({ fieldProps }) => (
                  <TextField
                    {...fieldProps}
                    type="password"
                    value={fields.confirmPassword}
                    onChange={(e) => handleChange("confirmPassword", e.target.value)}
                    required
                  />
                )}
              </Field>
            </FormSection>
            {error ? <p style={{ color: "red" }}>Error: {error}</p> : null}
            <FormFooter>
              <ButtonGroup>
                <Button appearance="subtle" onClick={() => navigate("/")}>
                  Cancel
                </Button>
                <LoadingButton type="submit" appearance="primary" isLoading={submitting}>
                  Sign Up
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
}
import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';


const Container = styled.div`
  position: relative;  // Ensure this is here for positioning the delete button
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? 'lightgreen' : 'white')};
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  cursor: pointer;
  font-size: 10px;
  line-height: 15px;  // This ensures the "X" is vertically centered
  text-align: center;  // This ensures the "X" is horizontally centered
  padding: 0;  // Remove any default padding
  display: flex;  // Use flexbox for centering
  justify-content: center;  // Center horizontally using flexbox
  align-items: center;  // Center vertically using flexbox
`;



export default class Task extends React.Component {
    render() {
      return (
        <Draggable draggableId={this.props.task.id} index={this.props.index}>
          {(provided, snapshot) => (
            <Container
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              innerRef={provided.innerRef}
              isDragging={snapshot.isDragging}
            >
              {this.props.task.content}
              <DeleteButton>X</DeleteButton>   {/* Add this line back */}
            </Container>
          )}
        </Draggable>
      );
    }
  }

import React from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import { useState } from "react";
import { Grid } from "@atlaskit/primitives";
import { Box, xcss } from "@atlaskit/primitives";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/standard-button";
import { DatePicker, TimePicker } from "@atlaskit/datetime-picker";
import Select from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import Form, { Field, FormFooter, FormSection, ValidMessage, ErrorMessage, } from "@atlaskit/form";
import TrashSymbol from "@atlaskit/icon/glyph/trash";
import AddSymbol from "@atlaskit/icon/glyph/add";
import EditSymbol from "@atlaskit/icon/glyph/edit";
import axios from "axios";
import { Footer } from "./Dashboard";
export const rowsReminders = [];
function createKey(input) {
    return input ? input.replace(/^(the|a|an)/, "").replace(/\s/g, "") : input;
}
const createHead = (withWidth) => {
    return {
        cells: [
            {
                key: "name",
                content: "Name",
                isSortable: true,
                width: withWidth ? 25 : undefined,
            },
            {
                key: "location",
                content: "Location",
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 15 : undefined,
            },
            {
                key: "date",
                content: "Date",
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 25 : undefined,
            },
            {
                key: "time",
                content: "Time",
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 20 : undefined,
            },
            {
                key: "type",
                content: "Type",
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 15 : undefined,
            },
        ],
    };
};
export const headReminders = createHead(true);
const ExternalBoxStyleProps = ({ windowWidth }) => {
    const zoomValue = (windowWidth / 1024).toString();
    const tempStyles = xcss({
        display: "grid",
        padding: "space.250",
        width: "100%",
        height: "100%",
        zoom: zoomValue,
    });
    return tempStyles;
};
const InternalBoxStyleProps = (area) => {
    return xcss({
        borderColor: "color.border.brand",
        borderStyle: "solid",
        borderRadius: "border.radius",
        borderWidth: "border.width",
        padding: "space.200",
        gridArea: area,
    });
};
var Action;
(function (Action) {
    Action[Action["ADD"] = 0] = "ADD";
    Action[Action["EDIT"] = 1] = "EDIT";
    Action[Action["TRASH"] = 2] = "TRASH";
})(Action || (Action = {}));
const addReminder = (Key, reminder) => {
    console.log("called");
    rowsReminders.push({
        key: Key,
        isHighlighted: false,
        cells: [
            {
                key: createKey(reminder.name),
                content: reminder.name,
            },
            {
                key: createKey(reminder.location),
                content: reminder.location,
            },
            {
                key: reminder.date,
                content: reminder.date,
            },
            {
                key: reminder.time,
                content: reminder.time,
            },
            {
                key: reminder.type,
                content: reminder.type,
            },
        ],
    });
};
export function populateReminders(reminders) {
    let i = 0;
    while (i < reminders.length) {
        let element = reminders[i++];
        addReminder(element.key, element);
    }
}
const delReminder = (key) => {
    let i = 0;
    while (i < rowsReminders.length) {
        console.log(rowsReminders[i].key);
        if (rowsReminders[i].key == key)
            break;
        ++i;
    }
    if (i < rowsReminders.length) {
        console.log(i);
        rowsReminders.splice(i, 1);
    }
};
async function sendAddReminder({ data, doTableRefresh }) {
    console.log("add Reminder Called");
    console.log(data);
    delete data.key;
    const response = await axios
        .post("/addReminder", data)
        .then((response) => {
        console.log(response.data);
        if (response.data.acknowledged == true) {
            console.log("reminder added");
            var key = response.data._id;
            data.key = key;
            delete data.username;
            data.type = data.type.value;
            addReminder(key, data);
            doTableRefresh();
            console.log(data);
        }
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
async function sendDelReminder({ userName, key, doTableRefresh }) {
    var data = { username: userName, key: key };
    const response = await axios
        .post("/delReminder", data)
        .then((response) => {
        console.log(response.data);
        if (response.data.acknowledged == true) {
            console.log("reminder delete confirmation received");
            delReminder(data.key);
            doTableRefresh();
        }
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
async function sendEditReminder({ data, doTableRefresh }) {
    console.log(data);
    const response = await axios
        .post("/editReminder", data)
        .then((response) => {
        console.log(response.data);
        if (response.data["acknowledged"] == true) {
            console.log("reminder added");
            delReminder(data.key);
            delete data.username;
            data.type = data.type.value;
            addReminder(data.key, data);
            doTableRefresh();
        }
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
function ConvertToJsonReminder(row) {
    var rv = {
        key: row.key,
        name: row.cells[0].content,
        location: row.cells[1].content,
        date: row.cells[2].content,
        time: row.cells[3].content,
        type: row.cells[4].content,
    };
    return rv;
}
const AddReminderForm = ({ selection, userName, doTableRefresh }) => {
    const validateField = (value) => {
        if (!value) {
            return "REQUIRED";
        }
    };
    const validateDateField = (value) => {
        if (!value) {
            return "REQUIRED";
        }
        var today = new Date().setHours(0, 0, 0, 0);
        var theDate = new Date(value).setHours(0, 0, 0, 0);
        if (theDate < today)
            return "EXPIRED";
    };
    var action = Action.ADD;
    return (<div style={{
            display: "flex",
            maxWidth: "100%",
            margin: "0 auto",
            flexDirection: "column",
        }}>
      <Form onSubmit={(data) => {
            data["key"] = selection.key;
            data["username"] = userName;
            var key = data.key;
            if (action == Action.ADD) {
                sendAddReminder({ data, doTableRefresh });
            }
            else if (action == Action.EDIT) {
                sendEditReminder({ data, doTableRefresh });
            }
            else if (action == Action.TRASH) {
                sendDelReminder({ userName, key, doTableRefresh });
            }
            // await new Promise((resolve) => setTimeout(resolve, 2000));
            // return data.username === "error" ? { username: "IN_USE" } : undefined;
        }}>
        {({ formProps, submitting }) => (<form {...formProps}>
            <FormSection>
              <Field aria-required={true} name="name" label="Reminder Name" isRequired defaultValue={selection.name}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>

              <Field aria-required={true} name="location" label="Location" isRequired defaultValue={selection.location}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>

              <Field name="date" label="Reminder Date" defaultValue={selection.date} validate={validateDateField} isRequired>
                {({ fieldProps, error, meta: { valid } }) => (<>
                    <DatePicker {...fieldProps} selectProps={{ inputId: fieldProps.id }}/>
                    {valid && (<ValidMessage>You have entered a valid date</ValidMessage>)}
                    {!valid && error === "REQUIRED" && (<ErrorMessage>This field is required</ErrorMessage>)}
                    {!valid && error === "EXPIRED" && (<ErrorMessage>Expired Date</ErrorMessage>)}
                  </>)}
              </Field>

              <Field name="time" label="Reminder time" defaultValue={selection.time} validate={validateField} isRequired>
                {({ fieldProps, error, meta: { valid } }) => (<>
                    <TimePicker {...fieldProps} selectProps={{ inputId: fieldProps.id }}/>
                    {valid && (<ValidMessage>You have entered a valid time</ValidMessage>)}
                    {!valid && (<ErrorMessage>This field is required</ErrorMessage>)}
                  </>)}
              </Field>

              <Field label="Reminder Type" name="type" id="owner" defaultValue={{
                label: selection.type,
                value: selection.type,
            }} isRequired>
                {({ fieldProps }) => (<Select isSearchable={false} inputId={fieldProps.id} options={[
                    { label: "General", value: "General" },
                    { label: "ToDo", value: "ToDo" },
                    { label: "Call", value: "Call" },
                ]} {...fieldProps} required/>)}
              </Field>
            </FormSection>

            <FormFooter>
              <ButtonGroup>
                <Button onClick={() => {
                action = Action.TRASH;
                console.log("Action: " + action);
                document.querySelector("form")?.requestSubmit();
            }} iconAfter={<TrashSymbol label="" size="medium"/>} appearance="primary"></Button>
                <Button onClick={() => {
                action = Action.EDIT;
                document.querySelector("form")?.requestSubmit();
            }} iconAfter={<EditSymbol label="" size="medium"/>} appearance="primary"></Button>
                <Button onClick={() => {
                action = Action.ADD;
                document.querySelector("form")?.requestSubmit();
            }} iconAfter={<AddSymbol label="" size="medium"/>} appearance="primary"></Button>
              </ButtonGroup>
            </FormFooter>
          </form>)}
      </Form>
    </div>);
};
export const RemindersContent = ({ windowWidth, userName }) => {
    const [tableRefresh, setTableRefresh] = useState(false);
    const doTableRefresh = () => {
        setTableRefresh(!tableRefresh);
    };
    let boxStyles = ExternalBoxStyleProps({ windowWidth });
    let modBoxStylesA = InternalBoxStyleProps("a");
    let modBoxStylesB = InternalBoxStyleProps("b");
    var today = new Date().toISOString().substring(0, 10);
    var sampleData = {
        key: "",
        name: "",
        location: "",
        date: today,
        time: "2:00 PM",
        type: "General",
    };
    const [selection, setSelection] = useState(sampleData);
    const [rowIndex, setRowIndex] = useState(-1);
    const extendRows = (rows, onClick) => {
        return rows.map((row, index) => ({
            ...row,
            onClick: (e) => onClick(e, index),
        }));
    };
    const onRowClick = (e, index) => {
        console.log(index);
        var jsonString = ConvertToJsonReminder(rowsReminders[index]);
        setSelection(jsonString);
        setRowIndex(index);
    };
    return (<Box xcss={boxStyles}>
      <Grid gap="space.600" templateAreas={["a .  b", "a .  b", "a .  b", "a . b", "f f f"]}>
        <Box backgroundColor="color.background.accent.lime.subtlest.hovered" xcss={modBoxStylesA}>
          <DynamicTable head={headReminders} rows={extendRows(rowsReminders, onRowClick)} highlightedRowIndex={rowIndex} rowsPerPage={10}/>
        </Box>
        <Box backgroundColor="color.background.accent.lime.subtlest.hovered" xcss={modBoxStylesB}>
          <AddReminderForm selection={selection} userName={userName} doTableRefresh={doTableRefresh}/>
        </Box>
        <Footer />
      </Grid>
    </Box>);
};

import React, { useState, useEffect } from "react";
import EditProfileForm from "../forms/editProfileForm";
import axios from "axios";
import { getAuth } from "firebase/auth";
import firebaseService from "../../services/firebase";

const GetProfilePage = () => {
  const [userData, setUserData] = useState({
    userName: "",
    displayName: "",
    email: "",
    gender: "",
    dob: "",
    phoneNum: "",
  });

  useEffect(() => {
    // Fetch the user's current profile information from the server
    const fetchUserProfile = async () => {
      try {
        const token = await firebaseService.auth.currentUser.getIdToken();
        // console.log(token);
  
        const user = getAuth().currentUser; // Use getAuth() to get Firebase auth object
        if (user !== null) {
          const response = await axios.get("http://localhost:5000/api/user", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const userProfileData = response.data;
          console.log(userProfileData);
          setUserData({
            userName: userProfileData.userName,
            displayName: userProfileData.displayName,
            email: userProfileData.email,
            gender: userProfileData.gender,
            dob: userProfileData.dob,
            phoneNum: userProfileData.phoneNum,
          });
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
  
    fetchUserProfile();
  }, []);
  

  return (
    <div>
      <h1>Profile</h1>
      <EditProfileForm userData={userData} />
    </div>
  );
};

export default GetProfilePage;

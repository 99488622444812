import React from 'react';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Task from './task';
import Button from "@atlaskit/button";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 2px;
  width: 220px;

  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  padding: 8px;
`;

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'lightgrey' : 'inherit'};
  flex-grow: 1;
  min-height: 100px;
`;

const Input = styled.input`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  margin-left: 5px;
`;

const AddTaskBar = styled.div`
  border-top: 1px solid lightgrey;
  padding: 10px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between; // to space out the input and the button
`;

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.tasks === this.props.tasks) {
      return false;
    }
    return true;
  }
  render() {
    return this.props.tasks.map((task, index) => (
      <Task key={task.id} task={task} index={index} />
    ));
  }
}


export default class Column extends React.Component {
    render() {
      return (
        <Draggable draggableId={this.props.column.id} index={this.props.index}>
          {provided => (
            <Container {...provided.draggableProps} innerRef={provided.innerRef}>
              <Title {...provided.dragHandleProps}>
                {this.props.column.title}
              </Title>
              <Droppable droppableId={this.props.column.id} type="task">
                {(provided, snapshot) => (
                  <TaskList
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <InnerList tasks={this.props.tasks} />
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
              <AddTaskBar>
                <Input placeholder="Add a task..." />
                <ButtonContainer>
                  <Button appearance="primary">Add</Button>
                </ButtonContainer>
              </AddTaskBar>
            </Container>
          )}
        </Draggable>
      );
    }
  }
  
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyDZsVsiq6xW2q6wO2s1t5pHjj0qyt99lGc",
    authDomain: "team-057.firebaseapp.com",
    projectId: "team-057",
    storageBucket: "team-057.appspot.com",
    messagingSenderId: "413494286314",
    appId: "1:413494286314:web:b7462449501a4855318493"
};
initializeApp(firebaseConfig);
const auth = getAuth();
export default {
    auth
};

const initialData = {
    tasks: {
      'task-1': { id: 'task-1', content: 'Write an outline for presentation' },
      'task-2': { id: 'task-2', content: 'Buy groceries' },
      'task-3': { id: 'task-3', content: 'Charge my phone' },
      'task-4': { id: 'task-4', content: 'Organizing files' },
      'task-5': { id: 'task-5', content: 'Preparing slides for the client ' },
      'task-6': { id: 'task-6', content: 'Update software ' },
      'task-7': { id: 'task-7', content: 'Sent out invites for party ' },
      'task-8': { id: 'task-8', content: 'Cleaned and organized the workspace' },
      'task-9': { id: 'task-9', content: 'charity run ' },
      'task-10': { id: 'task-10', content: 'Reviewing and editing report ' },
    },
    columns: {
      'column-1': {
        id: 'column-1',
        title: 'To do',
        taskIds: ['task-1', 'task-3', 'task-5', 'task-9', 'task-7'],
      },
      'column-2': {
        id: 'column-2',
        title: 'In progress',
        taskIds: ['task-2', 'task-6', 'task-4'],
      },
      'column-3': {
        id: 'column-3',
        title: 'Done',
        taskIds: ['task-8', 'task-10']
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ['column-1', 'column-2', 'column-3'],
  };
  
  export default initialData;
  
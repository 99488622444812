import React, { useRef } from "react";
import { useState } from "react";
import { Content, LeftSidebarWithoutResize, Main, PageLayout, TopNavigation } from "@atlaskit/page-layout";
import Button from "@atlaskit/button";
import Avatar from "@atlaskit/avatar";
import DynamicTable from "@atlaskit/dynamic-table";
import { Grid, Stack } from "@atlaskit/primitives";
import { Box, xcss } from "@atlaskit/primitives";
import Heading from "@atlaskit/heading";
import { AtlassianLogo } from "@atlaskit/logo";
import EditSymbol from "@atlaskit/icon/glyph/edit";
import Image from "@atlaskit/image";
import { ConnectionsContent } from "./ConnectionsContent";
import { RemindersContent } from "./RemindersContent";
import { EventsContent } from "./EventsContent";
import { headReminders, rowsReminders } from "./RemindersContent";
import { headEvents, rowsEvents } from "./EventsContent";
import { headConnections, rowsConnections } from "./ConnectionsContent";
import EditProfileForm from "./GetProfilePage";
import { TrelloLogInForm } from "../forms/trelloLoginForm";
import '@atlaskit/css-reset';
import styled from 'styled-components';
import { DragDropContext, Droppable,Draggable } from 'react-beautiful-dnd';
import initialData from '../initial-data';
import Column from '../column';
import task from "../task";

// for signing out
import firebaseService from "../../services/firebase";

const COLORA = "#d6ad60";
const COLORB = "#88A9C3";
const COLORC = "#8c8c8c";

export var Page;
(function (Page) {
    Page[Page["DASHBOARD"] = 0] = "DASHBOARD";
    Page[Page["CONNECTIONS"] = 1] = "CONNECTIONS";
    Page[Page["EVENTS"] = 2] = "EVENTS";
    Page[Page["REMINDERS"] = 3] = "REMINDERS";
    Page[Page["TRELLO"] = 4] = "TRELLO";
    Page[Page["EDIT_PROFILE"] = 5] = "EDIT_PROFILE";
    Page[Page["EDIT_CONNECTION"] = 6] = "EDIT_CONNECTION";
    Page[Page["EDIT_GROUP"] = 7] = "EDIT_GROUP";
})(Page || (Page = {}));
var lastPage = Page.DASHBOARD;
let footerStyle = xcss({
    borderColor: "color.border.brand",
    borderStyle: "solid",
    borderRadius: "border.radius",
    borderWidth: "border.width",
    backgroundColor: "color.background.accent.blue.subtlest.hovered",
    padding: "space.200",
    width: "100%",
    gridArea: "f",
});
const ExternalBoxStyleProps = ({ windowWidth }) => {
    const zoomValue = (windowWidth / 1024).toString();
    const tempStyles = xcss({
        display: "grid",
        padding: "space.250",
        width: "100%",
        height: "100%",
        zoom: zoomValue,
    });
    return tempStyles;
};
const InternalBoxStyleProps = (area) => {
    return xcss({
        borderColor: "color.border.brand",
        borderStyle: "solid",
        borderRadius: "border.radius",
        borderWidth: "border.width",
        padding: "space.200",
        gridArea: area,
    });
};
const InternalFramelessBoxStyleProps = (area) => {
    return xcss({
        padding: "space.200",
        gridArea: area,
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    });
};
const NavBar = ({ signIn, page, setPage, windowWidth }) => {
    var zoom = windowWidth / 1000;
    zoom = zoom > 1.0 ? 1.0 : zoom;
    const zoomValue = zoom.toString();
    let modBoxStylesA = xcss({
        gridArea: "a",
        alignContent: "center",
        padding: "space.100",
    });
    let modBoxStylesB = xcss({
        gridArea: "b",
        verticalAlign: "middle",
        padding: "space.050",
        marginLeft: "auto",
        marginRight: "30px",
    });
    const boxStyles = xcss({
        backgroundColor: "color.background.accent.blue.subtlest.hovered",
        display: "grid",
        width: "100%",
        height: "100%",
        zoom: zoomValue,
    });
    return (<TopNavigation testId="topNavigation" id="product-navigation" skipLinkTitle="Product Navigation" height={50} isFixed={false}>
      <Box xcss={boxStyles}>
        <Grid gap="space.600" templateAreas={[". a  b "]}>
          {page == Page.DASHBOARD && (<Box xcss={modBoxStylesA}>
              <Heading level="h700">Welcome! Here is your Dashboard.</Heading>
            </Box>)}
          {page == Page.CONNECTIONS && (<Box xcss={modBoxStylesA}>
              <Heading level="h700">Connections</Heading>
            </Box>)}
          {page == Page.EVENTS && (<Box xcss={modBoxStylesA}>
              <Heading level="h700">Events</Heading>
            </Box>)}
          {page == Page.REMINDERS && (<Box xcss={modBoxStylesA}>
              <Heading level="h700">Reminders</Heading>
            </Box>)}
          {page == Page.TRELLO && (<Box xcss={modBoxStylesA}>
              <AtlassianLogo appearance="brand"/>
            </Box>)}

          <Box xcss={modBoxStylesB}>
            <MyAvatar signIn={signIn} page={page} setPage={setPage}/>
          </Box>
        </Grid>
      </Box>
    </TopNavigation>);
};
const MyAvatar = ({ signIn, page, setPage }) => {
    const [dropdown, setDropdown] = useState(false);
    return (<Stack>
      {<Avatar 
        // src="https://pbs.twimg.com/profile_images/803832195970433027/aaoG6PJI_400x400.jpg"
        src="https://www.w3schools.com/howto/img_avatar2.png" size="large" onClick={() => setDropdown(!dropdown)}/>}
      {dropdown && (<DropdownStuff signIn={signIn} page={page} setPage={setPage} dropdown={dropdown} setDropdown={setDropdown}/>)}
    </Stack>);
};
const DropdownStuff = ({ signIn, page, setPage, dropdown, setDropdown }) => {
    const st = { position: "absolute", zIndex: 1 };
    const st2 = xcss({
        borderColor: "color.border.brand",
        borderStyle: "solid",
        borderRadius: "border.radius",
        borderWidth: "border.width",
        backgroundColor: "color.background.neutral.subtle.pressed",
    });
    const logUserOut = async () => {
      await firebaseService.auth.signOut();
    };
    return (<Box style={st}>
      <Stack space="space.050">
        <Avatar src="https://www.w3schools.com/howto/img_avatar2.png" size="large" onClick={() => setDropdown(false)}/>
        <Box xcss={st2}>
          <Stack>
            <Button onClick={() => {
            lastPage = page;
            setDropdown(!dropdown);
            setPage(Page.EDIT_PROFILE);
        }} appearance="subtle-link">
              Profile
            </Button>
            <Button onClick={logUserOut} appearance="subtle-link">
              Logout
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Box>);
};
const LeftSideBar = ({ setPage, windowWidth }) => {
    var zoom = windowWidth / 1000;
    zoom = zoom > 1.0 ? 1.0 : zoom;
    const zoomValue = zoom.toString();
    const boxStyles = xcss({
        display: "grid",
        padding: "space.250",
        width: "100%",
        height: "100%",
        zoom: zoomValue,
    });
    return (<LeftSidebarWithoutResize testId="leftSidebar" id="space-navigation" skipLinkTitle="Space Navigation" isFixed={false} width={125}>
      <Box xcss={boxStyles}>
        <Stack space="space.300">
          <Button onClick={() => setPage(Page.DASHBOARD)} appearance="primary" shouldFitContainer>
            Dashboard{" "}
          </Button>

          <Button onClick={() => setPage(Page.CONNECTIONS)} appearance="primary" shouldFitContainer>
            Connections{" "}
          </Button>

          <Button appearance="primary" shouldFitContainer onClick={() => setPage(Page.EVENTS)}>
            Events{" "}
          </Button>

          <Button appearance="primary" shouldFitContainer onClick={() => setPage(Page.REMINDERS)}>
            Reminders
          </Button>

          <Button appearance="primary" shouldFitContainer onClick={() => setPage(Page.TRELLO)}>
            Trello
          </Button>
        </Stack>
      </Box>
    </LeftSidebarWithoutResize>);
};
export function Footer() {
    return (<Box xcss={footerStyle}>
      <Heading level="h400" color={"inverse"}>
        Copyright: Built using the Atlassian Design System UI Component Library
        for IT Project. Computing and Software Systems. University of Melbourne.
      </Heading>
    </Box>);
}
const DashboardContent = ({ windowWidth }) => {
    let boxStyles = ExternalBoxStyleProps({ windowWidth });
    let modBoxStylesA = InternalBoxStyleProps("a");
    let modBoxStylesB = InternalBoxStyleProps("b");
    let modBoxStylesC = InternalBoxStyleProps("c");
    return (<Box xcss={boxStyles}>
      <Grid gap="space.600" templateAreas={["a .  b", "a .  b", "c c  c", "c c c", "f f f"]}>
        <Box backgroundColor="color.background.accent.lime.subtlest.hovered" xcss={modBoxStylesA}>
          <DynamicTable caption={"Today's Reminders"} head={headReminders} rows={rowsReminders} rowsPerPage={5} page={1}/>
        </Box>

        <Box backgroundColor="color.background.accent.yellow.subtlest.hovered" xcss={modBoxStylesB}>
          <DynamicTable caption={"Recent Connections"} head={headConnections} rows={rowsConnections} rowsPerPage={5} page={1}/>
        </Box>

        <Box backgroundColor="color.background.accent.teal.subtlest.hovered" xcss={modBoxStylesC}>
          <DynamicTable caption={"Today's events"} head={headEvents} rows={rowsEvents} rowsPerPage={5} page={1}/>
        </Box>
        <Footer />
      </Grid>
    </Box>);
};


const Container = styled.div`
  display: flex;
`;

class InnerList extends React.PureComponent {
  render() {
    const { column, taskMap, index } = this.props;
    const tasks = column.taskIds.map(taskId => taskMap[taskId]);
    return <Column column={column} tasks={tasks} index={index} />;
  }
}

const TrelloContent = ({ windowWidth }) => {
  const [state, setState] = useState(initialData);

  const onDragEnd = result => {
      const { destination, source, draggableId, type } = result;

      if (!destination) return;

      if (destination.droppableId === source.droppableId && destination.index === source.index) return;

      if (type === 'column') {
          const newColumnOrder = Array.from(state.columnOrder);
          newColumnOrder.splice(source.index, 1);
          newColumnOrder.splice(destination.index, 0, draggableId);

          const newState = {
              ...state,
              columnOrder: newColumnOrder,
          };
          setState(newState);
          return;
      }

      const home = state.columns[source.droppableId];
      const foreign = state.columns[destination.droppableId];

      if (home === foreign) {
          const newTaskIds = Array.from(home.taskIds);
          newTaskIds.splice(source.index, 1);
          newTaskIds.splice(destination.index, 0, draggableId);

          const newHome = {
              ...home,
              taskIds: newTaskIds,
          };

          const newState = {
              ...state,
              columns: {
                  ...state.columns,
                  [newHome.id]: newHome,
              },
          };

          setState(newState);
          return;
      }

      const homeTaskIds = Array.from(home.taskIds);
      homeTaskIds.splice(source.index, 1);
      const newHome = {
          ...home,
          taskIds: homeTaskIds,
      };

      const foreignTaskIds = Array.from(foreign.taskIds);
      foreignTaskIds.splice(destination.index, 0, draggableId);
      const newForeign = {
          ...foreign,
          taskIds: foreignTaskIds,
      };

      const newState = {
          ...state,
          columns: {
              ...state.columns,
              [newHome.id]: newHome,
              [newForeign.id]: newForeign,
          },
      };
      setState(newState);
  };

  return (
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
              {provided => (
                  <Container {...provided.droppableProps} ref={provided.innerRef}>
                      {state.columnOrder.map((columnId, index) => {
                          const column = state.columns[columnId];
                          return (
                              <InnerList
                                  key={column.id}
                                  column={column}
                                  index={index}
                                  taskMap={state.tasks}
                              />
                          );
                      })}
                      {provided.placeholder}
                  </Container>
              )}
          </Droppable>
      </DragDropContext>
  );
};

//export default TrelloContent;




const EditProfileContent = ({ windowWidth, page, setPage }) => {
    const fileUpload = useRef(null);
    const uploadProfilePic = (e) => {
        console.log(e.target.files[0]);
    };
    const handleUpload = () => {
        if (fileUpload.current != null)
            console.log(fileUpload.current.click(), "fileUpload");
    };
    const boxStyles = ExternalBoxStyleProps({ windowWidth });
    let modBoxStylesA = InternalFramelessBoxStyleProps("a");
    let modBoxStylesB = InternalFramelessBoxStyleProps("b");
    return (<Box padding="space.200" xcss={boxStyles}>
      <Grid gap="space.600" templateAreas={["a a b b b", "a a b b b", "a a b b b", "a a b b b"]}>
        <Box padding="space.200" backgroundColor="color.background.neutral" xcss={modBoxStylesA}>
          <Stack space="space.500">
            <Image style={{
            borderRadius: "100%",
            display: "block",
        }} src="https://www.w3schools.com/howto/img_avatar2.png"/>
            <input type="file" ref={fileUpload} onChange={uploadProfilePic} style={{ opacity: "0" }}/>
            <Button iconAfter={<EditSymbol label="" size="large"/>} appearance="subtle" onClick={() => handleUpload()}></Button>
          </Stack>
        </Box>
        <Box padding="space.200" backgroundColor="color.background.discovery" xcss={modBoxStylesB}>
          <EditProfileForm page={page} setPage={setPage}/>
        </Box>
      </Grid>
    </Box>);
};
export const Dashboard = ({ signIn, userName }) => {
    // setGlobalTheme({dark:"light"});
    const [page, setPage] = useState(Page.DASHBOARD);
    // console.log("curentpage is", page);
    let component = null;
    const [windowWidth, setwindowWidth] = useState(window.innerHeight);
    function handleResize() {
        setwindowWidth(window.innerHeight);
    }
    if (typeof window !== "undefined") {
        window.addEventListener("resize", handleResize);
    }
    const handleSetState = (newState) => {
        setPage(newState);
    };
    switch (page) {
        case Page.DASHBOARD:
            component = <DashboardContent windowWidth={windowWidth}/>;
            break;
        case Page.CONNECTIONS:
            component = (<ConnectionsContent windowWidth={windowWidth} userName={userName}/>);
            break;
        case Page.EVENTS:
            component = <EventsContent windowWidth={windowWidth} userName={userName}/>;
            break;
        case Page.REMINDERS:
            component = (<RemindersContent windowWidth={windowWidth} userName={userName}/>);
            break;
        case Page.TRELLO:
            component = <TrelloContent windowWidth={windowWidth}/>;
            break;
        case Page.EDIT_PROFILE:
            component = (<EditProfileContent windowWidth={windowWidth} page={lastPage} setPage={handleSetState}/>);
            break;
    }
    return (<PageLayout>
      <NavBar signIn={signIn} page={page} setPage={handleSetState} windowWidth={windowWidth}/>
      <Content testId="content">
        <LeftSideBar setPage={handleSetState} windowWidth={windowWidth}/>
        {<Main testId="main" id="main" skipLinkTitle="Main Content">
            {component}
          </Main>}
      </Content>
    </PageLayout>);
};

export default Dashboard;
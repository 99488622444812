import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignInPage from "../components/pages/LogInForm";
import SignUpPage from "../components/pages/SignUpForm";
import ResetPassword from "../components/pages/ResetPassword";

//import VerifyEmail from "../components/pages/EmailVerification";

export default function UnauthorizedRoutes() {
  return (<Router>
    <Routes>
      <Route path="/" element={<SignInPage />}/>
      <Route path="/signup" element={<SignUpPage />}/>
      <Route path="/passwordreset" element={<ResetPassword />}/>
      {/* <Route path="/verifyemail" element={<VerifyEmail />}/> */}
      <Route path="*" element={<main>
            <p>Not found.</p>
          </main>}/>
    </Routes>
  </Router>);
}

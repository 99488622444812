/** @jsxRuntime classic */
/** @jsx jsx */
import { useRef, useState, Fragment } from "react";
import { css, jsx } from '@emotion/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/standard-button";
import Select from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import EditSymbol from "@atlaskit/icon/glyph/edit";
import AddSymbol from "@atlaskit/icon/glyph/add";
import ArrowLeftSymbol from "@atlaskit/icon/glyph/arrow-left";
import { FormFooter, FormSection, } from "@atlaskit/form";
import { Box, xcss } from "@atlaskit/primitives";
import { Grid, Stack } from "@atlaskit/primitives";
import Image from "@atlaskit/image";
import DynamicTable from "@atlaskit/dynamic-table";
import Textfield from "@atlaskit/textfield";
import TrashSymbol from "@atlaskit/icon/glyph/trash";
import Lozenge from "@atlaskit/lozenge";
import Form, { Field, HelperMessage } from "@atlaskit/form";
import { Footer } from "./Dashboard";
import axios from "axios";
export var Create;
(function (Create) {
    Create[Create["EDIT"] = 0] = "EDIT";
    Create[Create["ADD"] = 1] = "ADD";
    Create[Create["NEITHER"] = 2] = "NEITHER";
})(Create || (Create = {}));
function createKey(input) {
    return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}
const nameWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
});
const NameWrapper = ({ children }) => (<span css={nameWrapperStyles}>{children}</span>);
const avatarWrapperStyles = css({
    marginRight: token('space.100', '8px'),
});
const AvatarWrapper = ({ children }) => (<div css={avatarWrapperStyles}>{children}</div>);
const createHead = (withWidth) => {
    return {
        cells: [
            {
                key: 'name',
                content: 'Name',
                isSortable: true,
                width: withWidth ? 25 : undefined,
            },
            {
                key: 'dob',
                content: 'DoB',
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 15 : undefined,
            },
            {
                key: 'email',
                content: 'Email',
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 10 : undefined,
            },
            {
                key: 'phone',
                content: 'Phone',
                isSortable: true,
                shouldTruncate: true,
            },
            {
                key: 'group',
                content: 'Group',
                isSortable: true,
                shouldTruncate: true,
            }
        ],
    };
};
export const headConnections = createHead(true);
export const rowsConnections = [];
const addConnection = (Key, connection) => {
    rowsConnections.push({
        key: Key,
        isHighlighted: false,
        cells: [
            {
                key: createKey(connection.name),
                content: (<NameWrapper>
            <AvatarWrapper>
              <Avatar src="https://www.w3schools.com/howto/img_avatar2.png" name={connection.name} size="medium"/>
            </AvatarWrapper>
            {connection.name}
          </NameWrapper>),
            },
            {
                key: createKey(connection.dob),
                content: connection.dob,
            },
            {
                key: connection.gender,
                content: connection.gender,
            },
            {
                key: connection.email,
                content: connection.email,
            },
            {
                key: connection.phone,
                content: connection.phone,
            },
            {
                key: connection.group,
                content: connection.group,
            },
        ],
    });
};
const ExternalBoxStyleProps = ({ windowWidth }) => {
    const zoomValue = (windowWidth / 1024).toString();
    const tempStyles = xcss({
        display: "grid",
        padding: "space.250",
        width: "100%",
        height: "100%",
        zoom: zoomValue,
    });
    return tempStyles;
};
const InternalBoxStyleProps = (area) => {
    return xcss({
        borderColor: "color.border.brand",
        borderStyle: "solid",
        borderRadius: "border.radius",
        borderWidth: "border.width",
        padding: "space.200",
        gridArea: area,
    });
};
const InternalFramelessBoxStyleProps = (area) => {
    return xcss({
        padding: "space.200",
        gridArea: area,
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    });
};
const boxStyles = xcss({
    color: "color.text",
    backgroundColor: "elevation.surface.overlay.hovered",
    borderWidth: "border.width",
    borderStyle: "solid",
    borderColor: "color.border.success",
    padding: "space.0",
    borderRadius: "border.radius.300",
    transitionDuration: "200ms",
    listStyle: "none",
    display: "grid",
    height: "size.400",
    // "::before": {
    //   content: '"✨"',
    //   paddingInlineEnd: "space.050",
    // },
    // "::after": {
    //   content: '"✨"',
    //   paddingInlineStart: "space.050",
    // },
    ":hover": {
        backgroundColor: "color.background.discovery.bold.hovered",
        color: "color.text.inverse",
        transform: "scale(1.02)",
    },
});
let groupList = [];
function GenerateOptions(groups) {
    var rv = [];
    for (var i = 0; i < groups.length; i++) {
        rv.push({ label: groups[i], value: groups[i] });
    }
    return rv;
}
export function populateConnections(connections) {
    let i = 0;
    while (i < connections.length) {
        let element = connections[i++];
        addConnection(element.key, element);
        if (!groupList.includes(element.group))
            groupList.push(element.group);
    }
}
const delConnection = (key) => {
    let i = 0;
    while (i < rowsConnections.length) {
        console.log(rowsConnections[i].key);
        if (rowsConnections[i].key == key)
            break;
        ++i;
    }
    if (i < rowsConnections.length) {
        console.log(i);
        rowsConnections.splice(i, 1);
    }
};
async function sendAddConnection({ data, doTableRefresh }) {
    console.log("add connection Called");
    console.log(data);
    delete data.key;
    const response = await axios
        .post("/addConnection", data)
        .then((response) => {
        console.log(response.data);
        if (response.data.acknowledged == true) {
            console.log("Connection added");
            var key = response.data._id;
            data.key = key;
            delete data.username;
            data.gender = data.gender.value;
            data.group = data.group.value;
            addConnection(key, data);
            doTableRefresh();
            console.log(data);
            console.log(rowsConnections[0]);
        }
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
async function sendDelConnection({ userName, key, doTableRefresh }) {
    var data = { username: userName, key: key };
    const response = await axios
        .post("/delConnection", data)
        .then((response) => {
        console.log(response.data);
        if (response.data.acknowledged == true) {
            console.log("Connection delete confirmation received");
            delConnection(data.key);
            doTableRefresh();
        }
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
async function sendEditConnection({ data, doTableRefresh }) {
    console.log(data);
    const response = await axios
        .post("/editConnection", data)
        .then((response) => {
        console.log(response.data);
        if (response.data["acknowledged"] == true) {
            console.log("Connection added");
            delConnection(data.key);
            delete data.username;
            data.gender = data.gender.value;
            data.group = data.group.value;
            addConnection(data.key, data);
            doTableRefresh();
        }
    })
        .catch((error) => {
        if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            console.log("Status :" + error.response.status);
        }
        else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else {
            // Error on setting up the request
            console.log("Error", error.message);
        }
    });
}
var Action;
(function (Action) {
    Action[Action["ADD"] = 0] = "ADD";
    Action[Action["EDIT"] = 1] = "EDIT";
    Action[Action["TRASH"] = 2] = "TRASH";
})(Action || (Action = {}));
const EditConnectionForm = ({ selection, newConnection, setNewConnection, groups, userName, doTableRefresh }) => {
    const validateField = (value) => {
        if (!value) {
            return "REQUIRED";
        }
    };
    const validateDateField = (value) => {
        if (!value) {
            return "REQUIRED";
        }
        var today = new Date().setHours(0, 0, 0, 0);
        var theDate = new Date(value).setHours(0, 0, 0, 0);
        if (theDate < today)
            return "EXPIRED";
    };
    var action = Action.ADD;
    return (<div style={{
            display: "flex",
            width: "312px",
            maxWidth: "100%",
            margin: "0 auto",
            flexDirection: "column",
        }}>
      <Form onSubmit={(data) => {
            data["key"] = selection.key;
            data["username"] = userName;
            var key = data.key;
            if (action == Action.ADD) {
                sendAddConnection({ data, doTableRefresh });
            }
            else if (action == Action.EDIT) {
                sendEditConnection({ data, doTableRefresh });
            }
            else if (action == Action.TRASH) {
                sendDelConnection({ userName, key, doTableRefresh });
            }
            // await new Promise((resolve) => setTimeout(resolve, 2000));
            // return data.username === "error" ? { username: "IN_USE" } : undefined;
        }}>
        {({ formProps, submitting }) => (<form {...formProps}>
            <FormSection>
              <Field aria-required={true} name="name" label="Name" isRequired defaultValue={selection.name}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>

              <Field aria-required={true} name="dob" label="Date of Birth" isRequired defaultValue={selection.dob}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>

              <Field label="gender" name="gender" id="owner" defaultValue={{ label: "Female", value: "female" }} isRequired>
                {({ fieldProps }) => (<Select isSearchable={false} inputId={fieldProps.id} options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                ]} {...fieldProps} required/>)}
              </Field>

              <Field aria-required={true} name="email" label="Email" isRequired defaultValue={selection.email}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>

              <Field aria-required={true} name="phone" label="Phone" isRequired defaultValue={selection.phone}>
                {({ fieldProps }) => (<TextField autoComplete="off" {...fieldProps}/>)}
              </Field>
              <Field label="Group" name="group" id="owner" defaultValue={{
                label: selection.group,
                value: selection.group,
            }}>
                {({ fieldProps }) => (<Select isSearchable={false} inputId={fieldProps.id} options={GenerateOptions(groups)} {...fieldProps}/>)}
              </Field>
            </FormSection>

            <FormFooter>
              <ButtonGroup>
                <Button onClick={() => setNewConnection(Create.NEITHER)} iconAfter={<ArrowLeftSymbol label="" size="medium"/>} appearance="primary"></Button>
                <Button onClick={() => {
                action = Action.EDIT;
                setNewConnection(Create.NEITHER);
                document.querySelector("form")?.requestSubmit();
            }} iconAfter={<EditSymbol label="" size="medium"/>} appearance="primary"></Button>
                <Button onClick={() => {
                setNewConnection(Create.NEITHER);
                action = Action.ADD;
                document.querySelector("form")?.requestSubmit();
            }} iconAfter={<AddSymbol label="" size="medium"/>} appearance="primary"></Button>
              </ButtonGroup>
            </FormFooter>
          </form>)}
      </Form>
    </div>);
};
const EditConnectionContent = ({ windowWidth, selection, newConnection, setNewConnection, groups, userName, doTableRefresh }) => {
    const fileUpload = useRef(null);
    const uploadProfilePic = (e) => {
        console.log(e.target.files[0]);
    };
    const handleUpload = () => {
        if (fileUpload.current != null)
            console.log(fileUpload.current.click(), "fileUpload");
    };
    const boxStyles = ExternalBoxStyleProps({ windowWidth });
    let modBoxStylesA = InternalFramelessBoxStyleProps("a");
    let modBoxStylesB = InternalFramelessBoxStyleProps("b");
    return (<Box padding="space.200" xcss={boxStyles}>
      <Grid gap="space.600" templateAreas={["a a b b b", "a a b b b", "a a b b b", "a a b b b"]}>
        <Box padding="space.200" backgroundColor="color.background.neutral" xcss={modBoxStylesA}>
          <Stack space="space.500">
            <Image style={{
            borderRadius: "100%",
            display: "block",
        }} src="https://pbs.twimg.com/profile_images/803832195970433027/aaoG6PJI_400x400.jpg"/>
            <input type="file" ref={fileUpload} onChange={uploadProfilePic} style={{ opacity: "0" }}/>
            <Button iconAfter={<EditSymbol label="" size="large"/>} appearance="subtle" onClick={() => handleUpload()}></Button>
          </Stack>
        </Box>
        <Box padding="space.200" backgroundColor="color.background.discovery" xcss={modBoxStylesB}>
          <EditConnectionForm selection={selection} newConnection={newConnection} setNewConnection={setNewConnection} groups={groups} userName={userName} doTableRefresh={doTableRefresh}/>
        </Box>
      </Grid>
    </Box>);
};
const GroupCard = ({ groupName, refreshPanel, setGroupName }) => {
    let modBoxStylesA = xcss({ gridArea: "a" });
    let modBoxStylesB = xcss({ gridArea: "b" });
    let modBoxStylesC = xcss({
        gridArea: "c",
        alignContent: "center",
        padding: "space.100",
    });
    return (<Box xcss={boxStyles}>
      <Grid gap="space.0" templateAreas={["a  c b b  b  b", "a  c b  b  b  b"]}>
        <Box xcss={modBoxStylesA}>
          <Button onClick={() => {
            groupList = removeElement(groupName);
            refreshPanel();
        }} iconAfter={<TrashSymbol label="" size="medium"/>} appearance="default"></Button>
        </Box>

        <Box xcss={modBoxStylesC}>
          <Lozenge appearance="new" isBold maxWidth={50}>
            17
          </Lozenge>
        </Box>

        <Box xcss={modBoxStylesB}>
          <Button onClick={() => setGroupName(groupName)} appearance="default" shouldFitContainer>
            {groupName}
          </Button>
        </Box>
      </Grid>
    </Box>);
};
function GroupsPanel(groups, refreshPanel, setGroupName) {
    return (<Grid templateColumns="1fr 1fr 1fr" gap="space.200" alignItems="center">
      {groups.map((group) => (<GroupCard groupName={group} refreshPanel={refreshPanel} setGroupName={setGroupName}></GroupCard>))}
    </Grid>);
}
function removeElement(name) {
    if (!groupList.includes(name))
        return groupList;
    let index = groupList.indexOf(name);
    return groupList
        .slice(0, index)
        .concat(groupList.slice(index + 1, groupList.length));
}
const MAX_LEN = 10;
function addElement(name) {
    if (name == undefined)
        return;
    if (name.length > MAX_LEN)
        return;
    if (name.match(/^[a-z0-9]+$/i) == null)
        return;
    if (!groupList.includes(name))
        groupList.push(name);
}
const AddGroupContent = ({ setAddGroup, xcss }) => {
    return (<Box backgroundColor="color.background.accent.yellow.subtlest.hovered" xcss={xcss}>
      <Form onSubmit={(formState) => {
            addElement(formState["group-name"]);
            setAddGroup(false);
        }}>
        {({ formProps }) => (<form {...formProps}>
            <Stack space="space.300">
              <Field label="GroupName" name="group-name">
                {({ fieldProps }) => (<Fragment>
                    <Textfield placeholder="Enter a group name here" {...fieldProps}/>
                    <HelperMessage>
                      Group Name not more than 10 characters.
                    </HelperMessage>
                  </Fragment>)}
              </Field>
              <ButtonGroup>
                <Button onClick={() => setAddGroup(false)} iconAfter={<ArrowLeftSymbol label="" size="medium"/>} appearance="primary"></Button>
                <Button type="submit" iconAfter={<AddSymbol label="" size="medium"/>} appearance="primary"></Button>
              </ButtonGroup>
            </Stack>
          </form>)}
      </Form>
    </Box>);
};
function ConvertToJsonConnection(row) {
    var rv = {
        key: row.key,
        name: row.cells[0].key,
        dob: row.cells[1].content,
        gender: row.cells[2].content,
        email: row.cells[3].content,
        phone: row.cells[4].content,
        group: row.cells[5].content
    };
    return rv;
}
export const ConnectionsContent = ({ windowWidth, userName }) => {
    const [tableRefresh, setTableRefresh] = useState(false);
    const doTableRefresh = () => {
        setTableRefresh(!tableRefresh);
    };
    const [panelRefresh, setpanelRefresh] = useState(false);
    const refreshPanel = () => {
        setpanelRefresh(!panelRefresh);
    };
    let boxStyles = ExternalBoxStyleProps({ windowWidth });
    let modBoxStylesA = InternalBoxStyleProps("a");
    let modBoxStylesB = InternalFramelessBoxStyleProps("b");
    let modBoxStylesC = InternalBoxStyleProps("c");
    let modBoxStylesD = InternalFramelessBoxStyleProps("d");
    const [addGroup, setAddGroup] = useState(false);
    const [newConnection, setNewConnection] = useState(Create.NEITHER);
    var sampleData = {
        key: "",
        name: "",
        gender: "male",
        dob: "",
        email: "",
        phone: "",
        group: "",
    };
    const [selection, setSelection] = useState(sampleData);
    const [rowIndex, setRowIndex] = useState(0);
    const [groupName, setGroupName] = useState("");
    const extendRows = (rows, onClick) => {
        return rows.map((row, index) => ({
            ...row,
            onClick: (e) => onClick(e, index),
        }));
    };
    const onRowClick = (e, index) => {
        var jsonString = ConvertToJsonConnection(rowsConnections[index]);
        setSelection(jsonString);
        setRowIndex(index);
    };
    /*grid to layout 4 components*/
    return ((newConnection != Create.NEITHER && (<EditConnectionContent windowWidth={windowWidth} selection={selection} newConnection={newConnection} setNewConnection={setNewConnection} groups={groupList} userName={userName} doTableRefresh={doTableRefresh}/>)) || (<Box xcss={boxStyles}>
        <Grid gap="space.600" templateAreas={["a a  b", "a a  b", "c c  d", "c c d", " f f f"]}>
          <Box backgroundColor="color.background.accent.yellow.subtlest.hovered" xcss={modBoxStylesA}>
            {GroupsPanel(groupList, refreshPanel, setGroupName)}
          </Box>

          {!addGroup && (<Box xcss={modBoxStylesB}>
              <ButtonGroup>
                <Button onClick={() => setAddGroup(true)} iconAfter={<AddSymbol label="" size="medium"/>} appearance="primary"></Button>
              </ButtonGroup>
            </Box>)}
          {addGroup && (<AddGroupContent setAddGroup={setAddGroup} xcss={modBoxStylesB}/>)}

          <Box xcss={modBoxStylesC} backgroundColor="color.background.accent.yellow.subtlest.hovered">
            <DynamicTable head={headConnections} rows={extendRows(rowsConnections, onRowClick).filter((row) => {
            // console.log(row.cells[2].content);
            return groupName == row.cells[5].content;
        })} highlightedRowIndex={rowIndex} rowsPerPage={5} page={1}/>
          </Box>
          <Box xcss={modBoxStylesD}>
            <ButtonGroup>
              <Button onClick={() => {
            var key = selection.key;
            sendDelConnection({ userName, key, doTableRefresh });
        }} iconAfter={<TrashSymbol label="" size="medium"/>} appearance="primary"></Button>
              <Button onClick={() => setNewConnection(Create.EDIT)} iconAfter={<EditSymbol label="" size="medium"/>} appearance="primary"></Button>
              <Button onClick={() => {
            setNewConnection(Create.ADD);
            setSelection(sampleData);
        }} iconAfter={<AddSymbol label="" size="medium"/>} appearance="primary"></Button>
            </ButtonGroup>
          </Box>
          <Footer />
        </Grid>
      </Box>));
};
